import React from "react";
import "./dashboard.css";
import { Grid, Box, Typography } from "@mui/material";
import screen from "../../Assets/icons/Vector.svg";
import screen1 from "../../Assets/icons/iconoir_page.svg";
import screen2 from "../../Assets/icons/Vector a.svg";
import User from "../../Assets/icons/user.svg";

const DashboardTopBoxes = ({
  contents,
  isContentLoading,
  screens,
  screenLoading,
  playlist,
  isPlaylistLoading,
  user,
}) => {
  return (
    <>
      <Grid>
        <div className="mainbox">
          <div className="box1">
            <div
              className="circle"
              style={{ background: "hsla(337, 79%, 61%, 1)" }}
            >
              {user?.image?.url == "" ? (
                <img src={User} alt="" />
              ) : (
                <img
                  src={`${user?.image?.url}`}
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "50%",
                    color: "#551fff",
                    marginTop: "-0px",
                  }}
                />
              )}
            </div>
            <span>{user?.email}</span>
          </div>
          <div className="boxe2">
            <div
              className="circle"
              style={{ background: "hsla(143, 53%, 50%, 1)" }}
            >
              <img src={screen} alt="" />
            </div>
            <span>Screen</span>
            {screenLoading ? (
              <Box className="minLoader"></Box>
            ) : (
              <Typography
                sx={{
                  color: "#92959E",
                  fontSize: "14px",
                }}
              >
                {screens.length}
              </Typography>
            )}
          </div>
          <div className="boxe2">
            <div
              className="circle"
              style={{ background: " hsla(209, 99%, 65%, 1)" }}
            >
              <img src={screen1} alt="" />
            </div>
            <span>Content</span>
            {isContentLoading ? (
              <Box className="minLoader"></Box>
            ) : (
              <Typography
                sx={{
                  color: "#92959E",
                  fontSize: "14px",
                }}
              >
                {contents.length}
              </Typography>
            )}
          </div>
          <div className="boxe2">
            <div
              className="circle"
              style={{ background: " hsla(14, 81%, 46%, 1)" }}
            >
              <img src={screen2} alt="" />
            </div>
            <span>Playlist</span>
            {isPlaylistLoading ? (
              <Box className={"minLoader"}></Box>
            ) : (
              <Typography
                sx={{
                  color: "#92959E",
                  fontSize: "14px",
                }}
              >
                {playlist.length}
              </Typography>
            )}
          </div>
        </div>
      </Grid>
    </>
  );
};

export default DashboardTopBoxes;
