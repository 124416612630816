import {
  Dialog,
  DialogContent,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  Grid,
  FormControl,
  InputAdornment,
  IconButton,
  Input,
  Typography,
  TablePagination,
  Button,
  Popover,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../components/layout/Layout";
import "./user.css";
import {
  deleteUser,
  editUser,
  getUsers,
  login,
  userFlagOff,
  userRegister,
} from "../../Redux/Actions/userAction";
import { Box } from "@mui/system";
import { styled, alpha } from "@mui/material/styles";
import { ReactComponent as DeleteIcon } from "../../Assets/images/delete.svg";
import {
  DeleteOutlineOutlined,
  Edit,
  EditOutlined,
  SettingsRemoteOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import PrimarySearchBar from "../../components/searchBar/searchbar";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import DeleteModel from "../../components/deletemodel/DeleteModel";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import useFilter from "../../hooks/useFilter";
import PrevilliageForm from "../Role/Component/PrevilliageForm";
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
const CustomTableCell = styled(TableCell)(({ theme }) => ({
  color: "#15192C",
  fontSize: "14px",
  fontWeight: "700",
}));

function User({ user }) {
  const navigate = useNavigate();
  const [range, setRange] = useState({ from: null, to: null });
  const [showPassword, setShowPassword] = React.useState(false);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm();
  const dispatch = useDispatch();
  const {
    isUserLoading,
    users,
    isUserAdded,
    isAlreadyAvailable,
    isUserDeleted,
  } = useSelector((state) => state.User);
  const { previlliages } = useSelector((state) => state.Previlliage);
  const [openForm, setOpenForm] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [ids, setIds] = useState("");
  const [userId, setUserId] = useState("");
  const [textVal, setTextVal] = useState("");
  const [filteredUser, setFilteredUser] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [role, setRole] = useState("user");
  const [editMode, setEditMode] = useState(false);
  const [form, setForm] = useState({
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    screenlimit: "",
    password: "",
    role: "",
    // license: "",
    from:'',
    to:''
  });
  const [prevData, setPrevData] = useState({
    // name: "",
    screen: {
      canCreate: false,
      canEdit: false,
      canDelete: false,
    },
    content: {
      canCreate: false,
      canEdit: false,
      canDelete: false,
    },
    playlist: {
      canCreate: false,
      canEdit: false,
      canDelete: false,
    },
    folder: {
      canCreate: false,
      canEdit: false,
      canDelete: false,
    },
  });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const filtered = useFilter(textVal, users);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const submitUser = () => {
    let notEmpty=false;
    let field='';
    for (let [keys, values] of Object.entries(form)){
      if(values=='' || values==""){
        notEmpty=true;
        field=keys;
        break;
      }else{
        notEmpty=false;
        continue;
      }
    }
    if(notEmpty){
      toast.error(`${field=='from' ? "License" : field} is required`);
      return;
    }
   console.log(form)
    dispatch(
      userRegister({
        ...form,
        user: user._id,
        previlliages: prevData,
      })
    );
    onCloseForm();
    // setOpenForm(false);
  };

  const onEditUser = () => {
    // const { email, username, password, validity, screenLimit, previlliages } =
    //   data;
    dispatch(
      editUser(ids, {
        ...form,
        previlliages: prevData,
      })
    );
    onCloseForm();
    // setOpenForm(false);
    // reset({
    //   email: "",
    //   username: "",
    //   password: "",
    //   // validity:'',
    //   screenLimit: "",
    //   previlliages: "",
    // });
  };

  useEffect(() => {
    dispatch(getUsers(user?._id));
  }, []);
  useEffect(() => {
    if (isUserAdded) {
      toast.success("user added successfully");
      setFilteredUser([...users]);
      dispatch(userFlagOff());
      setOpenForm(false);
    }
    if (isAlreadyAvailable) {
      toast.error("User already registered");
      dispatch(userFlagOff());
    }
    if (isUserDeleted) {
      toast.success("user deleted");
      setFilteredUser([...users]);
      dispatch(userFlagOff());
    }
  }, [isUserAdded, isAlreadyAvailable, isUserDeleted]);

  const selectedVal = watch("previlliages");
  const _onEdit = (_data) => {
    // setValue("email", _data.email);
    // setValue("username", _data.username);
    // setValue("password", _data.password);
    // setValue("role", _data.role);
    // setValue("validity", new Date(_data.validity).toISOString().split("T")[0]);
    // setValue("screenLimit", _data.screenLimit);
    // setValue("previlliages", _data.previlliages._id);
    setForm({
      firstname: _data.firstname,
      lastname: _data.lastname,
      username: _data.username,
      role: _data.role,
      password: _data.password,
      screenlimit: _data.screenLimit,
      from:new Date(_data.licenseStarted).toISOString(),
      to:new Date(_data.licenseTill).toISOString(),
      // license: _data.license,
      email: _data.email,
    });
    console.log(new Date(_data.licenseStarted).toUTCString(),_data.licenseStarted)
    setRange({from:new Date(_data.licenseStarted).toUTCString(),to:new Date(_data.licenseTill).toUTCString()})
    setPrevData({
      content: _data.content,
      screen: _data.screen,
      playlist: _data.playlist,
      folder: _data.folder,
    });

    setIds(_data._id);
    setEditMode(true);
    setOpenForm(true);
  };
  const askingForDelete = (id) => {
    setUserId(id);
    setOpenDeleteModel(true);
  };
  const confirmDelete = () => {
    dispatch(deleteUser(userId));
    setOpenDeleteModel(false);
  };
  const searchHandler = (e) => {
    setTextVal(e);
    if (e !== "") {
      const searched = users.filter((u) => {
        return Object.values(u)
          .join("")
          .toLocaleLowerCase()
          .includes(e.toLocaleLowerCase());
      });
      setFilteredUser([...searched]);
    } else {
      setFilteredUser([...users]);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const onCloseEditForm = () => {
    setValue("email", "");
    setValue("username", "");
    setValue("password", "");
    // setValue("role",'');
    setValue("validity", "");
    setValue("screenLimit", "");
    setIds("");
    setEditMode(false);
    setOpenEditForm(false);
  };
  const handleSwitchInput = (e) => {
    const { name, checked } = e.target;
    const keys = name.split("-");
    setPrevData((prev) => ({
      ...prev,
      [keys[0]]: { ...prev[keys[0]], [keys[1]]: checked },
    }));
  };
  const onGetValues = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };
  const onGetRange=(e)=>{
    console.log(e)
    setForm((prev)=>({...prev,from:e.from,to:e.to}));
    setRange(e);
  }
  const onCloseForm = () => {
    setForm({
      firstname: "",
      lastname: "",
      screenlimit: "",
      role: "",
      email: "",
      username: "",
      password: "",
      // license: "",
    });
    setPrevData({
      // name: "",
      screen: {
        canCreate: false,
        canEdit: false,
        canDelete: false,
      },
      content: {
        canCreate: false,
        canEdit: false,
        canDelete: false,
      },
      playlist: {
        canCreate: false,
        canEdit: false,
        canDelete: false,
      },
      folder: {
        canCreate: false,
        canEdit: false,
        canDelete: false,
      },
    });
    setIds("");
    setEditMode(false);
    setOpenForm(false);
  };
  const submitLogin = (email, password) => {
    if (email === "") {
      return;
    }
    if (password === "") {
      return;
    }
    dispatch(login(email, password));
    return navigate("/");
  };
  
  return (
    <>
      {/* {
      console.log(users)
    } */}
      <Layout title={"User"} user={user}>
        <div className="parent-container">
          <div className="child-container">
            <Grid container>
              <Box className={"control-area"}>
                <Grid lg={8}>
                  <Box
                    sx={{
                      background: "white",
                      padding: "33px 0",
                      marginRight: "20px !important",
                      borderRadius: "16px",
                    }}
                  >
                    <PrimarySearchBar
                      value={textVal}
                      onChange={searchHandler}
                    />
                  </Box>
                </Grid>
                <Grid lg={4} display={"flex"} justifyContent={"flex-end"}>
                  <div className="user-add-btn">
                    <button onClick={() => setOpenForm(true)}>+Add User</button>
                  </div>
                </Grid>
              </Box>
            </Grid>
          </div>

          <div className="table">
            <TableContainer
              component={Paper}
              sx={{
                borderRadius: "16px",
              }}
            >
              <Table className="table-background">
                <TableHead>
                  <TableRow
                    style={{ borderBottom: "2px solid var(--primary-color)" }}
                  >
                    <CustomTableCell align="center">Roles</CustomTableCell>
                    <CustomTableCell align="center">User Names</CustomTableCell>
                    <CustomTableCell align="center">
                      Company Visitor
                    </CustomTableCell>
                    <CustomTableCell align="center">
                      License Duration
                    </CustomTableCell>
                    <CustomTableCell align="center">Status</CustomTableCell>
                    {/* <CustomTableCell align="center">ID</CustomTableCell>
                    <CustomTableCell align="center">Date</CustomTableCell>
                    <CustomTableCell align="center">Email</CustomTableCell> */}
                    <CustomTableCell align="center">Action</CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isUserLoading ? (
                    <>
                      <Loader />
                    </>
                  ) : (
                    filtered.length > 0 &&
                    filtered
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => (
                        <>
                          <TableRow>
                            {/* <TableCell align="center">{++index}</TableCell> */}
                            <TableCell align="center">
                              {row?.role}
                              {/* {new Date(row.createdAt).toLocaleString()} */}
                            </TableCell>
                            <TableCell align="center">
                              {row?.username}
                            </TableCell>
                            <TableCell align="center">
                              <>?</>
                              {/* {row.email} */}
                            </TableCell>
                            <TableCell align="center">
                              {`${new Date(
                                row?.licenseStarted
                              ).toDateString()} to ${new Date(
                                row?.licenseTill
                              ).toDateString()}`}
                              {/* {row?.license == 1
                                ? `${row?.license} year`
                                : `${row?.license} years`} */}
                            </TableCell>
                            <TableCell align="center">
                              {row?.isLicensed ? (
                                Date.now() > new Date(row?.licenseTill) ? (
                                  <>
                                    <div className="status-badge license-expires"></div>
                                  </>
                                ) : row?.onlinesStatus ? (
                                  <>
                                    <div className="status-badge success"></div>
                                  </>
                                ) : (
                                  <>
                                    <div className="status-badge danger"></div>
                                  </>
                                )
                              ) : row?.isLicensed == false ? (
                                <>
                                  <div className="status-badge license-waiting"></div>
                                </>
                              ) : (
                                <>
                                  <div className="status-badge license-expires"></div>
                                </>
                              )}
                            </TableCell>
                            <TableCell align="center">
                              <Visibility
                                onClick={
                                  () => submitLogin(row?.email, row?.password)
                                  // navigate(`/user-panel/${row._id}`)
                                }
                                style={{
                                  cursor: "pointer",
                                  margin: "-3px 5px",
                                  color: "var(--primary-text)",
                                }}
                              />
                              <EditOutlined
                                ontSize={"medium"}
                                onClick={() => _onEdit(row)}
                                sx={{
                                  color: "var(--primary-text)",
                                }}
                              />
                              <DeleteOutlineOutlined
                                fontSize={"medium"}
                                onClick={() => askingForDelete(row?._id)}
                                sx={{
                                  color: "var(--primary-text)",
                                }}
                              ></DeleteOutlineOutlined>
                            </TableCell>
                          </TableRow>
                        </>
                      ))
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                style={{ display: "flex", justifyContent: "space-between" }}
                count={filtered.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              ></TablePagination>
            </TableContainer>
          </div>
        </div>

        <DeleteModel
          open={openDeleteModel}
          onClose={(e) => setOpenDeleteModel(e)}
          onConfirm={confirmDelete}
        />

        <Dialog
          maxWidth={"lg"}
          open={openForm}
          onClose={onCloseForm}
          // fullWidth
        >
          <DialogTitle
            style={{
              textAlign: "center",
              borderBottom: "2px solid var(--primary-color)",
              fontSize: "18px",
              fontWeight: 700,
            }}
          >
            {editMode ? "Edit User" : "Add User"}
          </DialogTitle>
          <DialogContent>
            {/* <form onSubmit={handleSubmit((data)=>console.log(data))}> */}
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <div
                style={{
                  width: "50vw",
                  minHeight: "10vh",
                  display: "flex",
                  justifyContent: "cenetr",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <>
                  <TextField
                    name={"firstname"}
                    value={form.firstname}
                    onChange={onGetValues}
                    type={"text"}
                    variant={"standard"}
                    label={"First Name"}
                    fullWidth
                    required
                  />
                </>
                <>
                  <TextField
                    name={"lastname"}
                    value={form.lastname}
                    onChange={onGetValues}
                    type={"text"}
                    variant={"standard"}
                    label={"Last Name"}
                    fullWidth
                    required
                  />
                </>
                <>
                  <TextField
                    name={"username"}
                    value={form.username}
                    onChange={onGetValues}
                    type={"text"}
                    variant={"standard"}
                    label={"Username"}
                    fullWidth
                    required
                  />
                </>
              </div>

              <div
                style={{
                  width: "50vw",
                  minHeihgt: "10vh",
                  display: "flex",
                  gap: "10px",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <>
                  <TextField
                    name={"email"}
                    value={form.email}
                    onChange={onGetValues}
                    type={"email"}
                    variant={"standard"}
                    label={"Email*"}
                    fullWidth
                    required
                  />
                </>
                <>
                  <FormControl sx={{ width: "100%" }} variant={"outlined"}>
                    <InputLabel htmlFor="standard-adornment-password">
                      Password*
                    </InputLabel>
                    <Input
                      name={"password"}
                      value={form.password}
                      onChange={onGetValues}
                      required
                      id="standard-adornment-password"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? (
                              <VisibilityOff
                                sx={{
                                  color: "#551FFF",
                                }}
                              />
                            ) : (
                              <Visibility
                                sx={{
                                  color: "#551FFF",
                                }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  {/* <Typography>
                      {errors.password && "password is required"}
                    </Typography> */}
                </>
                <>
                  <FormControl sx={{ width: "100%" }} variant="outlined">
                    <InputLabel htmlFor="standard-adornment-password">
                      Password*
                    </InputLabel>
                    <Input
                      id="standard-adornment-password"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? (
                              <VisibilityOff
                                sx={{
                                  color: "#551FFF",
                                }}
                              />
                            ) : (
                              <Visibility
                                sx={{
                                  color: "#551FFF",
                                }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  {/* <Typography>
                    {errors.password && "password is required"}
                  </Typography>  */}
                </>
              </div>

              <div
                style={{
                  width: "50vw",
                  minHeight: "10vh",
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <>
                  <TextField
                    name={"screenlimit"}
                    value={form.screenlimit}
                    onChange={onGetValues}
                    type={"text"}
                    variant={"standard"}
                    label={"No Of Screens*"}
                    fullWidth
                    required
                  />
                  {/* <Typography>
                      {errors.screenLimit && "no of screens is required"}
                    </Typography> */}
                </>
                <>
                  <Select
                    variant={"standard"}
                    name={"role"}
                    value={form.role}
                    onChange={onGetValues}
                    fullWidth
                    required
                  >
                    <MenuItem value="">--select role--</MenuItem>
                    <MenuItem value="user-admin">User admin</MenuItem>
                    <MenuItem value="user">user</MenuItem>
                  </Select>
                </>
              </div>

              <Box
                style={{
                  width: "50vw",
                  minHeihgt: "10dvh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  margin: "10px 0",
                }}
              >
                <>
                <TextField value={!range.from ? '' : `From:${range.from ? new Date(range.from).toDateString() : ''} to:${range.to ? new Date(range.to).toDateString() : ''}`} readOnly fullWidth aria-describedby={id} onClick={handleClick} placeholder="select license trial." />
                {/* <Button aria-describedby={id} variant="contained" onClick={handleClick}>
        Open Popover
      </Button> */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div style={{padding:'10px'}}>
        {/* <Typography sx={{ p: 2 }}>Select license duration.</Typography> */}
                  <DayPicker
                    mode="range"
                    selected={range}
                    onSelect={onGetRange}
                    required
                  />
<button style={{height:'30px',width:'100px',background:'white',boder:'1px solid gray',borderRadius:'5px',color:'black',textAlign:'center'}} onClick={()=>setRange({from:null,to:null})}>Clear</button>
        </div>
      </Popover>
                  {/* <Select
                    variant={"standard"}
                    name={"license"}
                    value={form.license}
                    onChange={onGetValues}
                    required
                    fullWidth
                  >
                    <MenuItem value="">--select license--</MenuItem>
                    <MenuItem value="1">1 year</MenuItem>
                    <MenuItem value="2">2 years</MenuItem>
                    <MenuItem value="3">3 years</MenuItem>
                  </Select> */}
                </>
              </Box>
            </Box>

            <>
              <Box
                sx={{
                  // border: '1px solid black',
                  width: "50vw",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  gap: "10px",
                  margin: "10px auto",
                }}
              >
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow style={{ background: "#eff4fa" }}>
                        <TableCell>Role Permission</TableCell>
                        <TableCell>Create</TableCell>
                        <TableCell>Edit</TableCell>
                        <TableCell>Delete</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.keys(prevData).map((key, index) => {
                        if (key == "screen") {
                          return (
                            <PrevilliageForm
                              index={index}
                              _key={key}
                              prevData={prevData}
                              handleSwitchInput={handleSwitchInput}
                            />
                          );
                        }
                        if (key == "content") {
                          return (
                            <PrevilliageForm
                              index={index}
                              _key={key}
                              prevData={prevData}
                              handleSwitchInput={handleSwitchInput}
                            />
                          );
                        }
                        if (key == "playlist") {
                          return (
                            <PrevilliageForm
                              index={index}
                              _key={key}
                              prevData={prevData}
                              handleSwitchInput={handleSwitchInput}
                            />
                          );
                        }
                        if (key == "folder") {
                          return (
                            <PrevilliageForm
                              index={index}
                              _key={key}
                              prevData={prevData}
                              handleSwitchInput={handleSwitchInput}
                            />
                          );
                        }
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </>

            <>
              <div
                style={{
                  width: "50vw",
                  minHeihgt: "10dvh",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <button
                  onClick={editMode ? onEditUser : submitUser}
                  type={"submit"}
                  style={{
                    height: "3vh",
                    width: "6vw",
                    cursor: "pointer",
                    borderRadius: "5px",
                    border: "1px solid gray",
                    background: "var(--primary-color)",
                    color: "white",
                  }}
                >
                  {editMode ? "Edit User" : "Add User"}
                </button>
                <button
                  onClick={onCloseForm}
                  type={"button"}
                  style={{
                    height: "3vh",
                    width: "6vw",
                    cursor: "pointer",
                    borderRadius: "5px",
                    border: "1px solid gray",
                    background: "white",
                    color: "black",
                  }}
                >
                  Cancel
                </button>
              </div>
            </>
            {/* </form> */}
          </DialogContent>
        </Dialog>

<Dialog>

</Dialog>

        {/* <Dialog
          maxWidth={"xl"}
          open={openForm}
          onClose={() => setOpenForm(false)}
          sx={{
            "& .MuiPaper-root-MuiDialog-paper": {
              borderRadius: "16px",
            },
          }}
          className="dialogBorder"
        >
          <DialogTitle
            style={{
              textAlign: "center",
              borderBottom: "2px solid var(--primary-color)",
              fontSize: "18px",
              fontWeight: 700,
            }}
          >
            Add User
          </DialogTitle>
          <DialogContent
            sx={{
              pb: 5,
            }}
          >
            {/* <form onSubmit={handleSubmit(submitUser)}> 
            <div className="user-add-form-container">
              <div className="form-fields">
                <TextField
                  {...register("username", {
                    required: true,
                  })}
                  type={"text"}
                  variant={"standard"}
                  label={"Name*"}
                  fullWidth
                />
                <Typography>
                  {errors.username && "username is required"}
                </Typography>
              </div>
              <div className="form-fields">
                <TextField
                  {...register("email", {
                    required: true,
                  })}
                  type={"email"}
                  variant={"standard"}
                  label={"forexample@gmail.com"}
                  fullWidth
                />
                <Typography>{errors.email && "email is required"}</Typography>
              </div>
              <div className="form-fields">
                <FormControl sx={{ width: "100%" }} variant="standard">
                  <InputLabel htmlFor="standard-adornment-password">
                    Password*
                  </InputLabel>
                  <Input
                    {...register("password", {
                      required: true,
                    })}
                    id="standard-adornment-password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? (
                            <VisibilityOff
                              sx={{
                                color: "#551FFF",
                              }}
                            />
                          ) : (
                            <Visibility
                              sx={{
                                color: "#551FFF",
                              }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <Typography>
                  {errors.password && "password is required"}
                </Typography>
              </div>

              <div className="form-fields">
                <FormControl variant={"standard"} fullWidth>
                  <InputLabel id="role-id">Role</InputLabel>
                  <Input
                    labelId={"role-id"}
                    type={"text"}
                    readOnly
                    disabled
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                  />
                </FormControl>
                {/* <Select
                  variant={'standard'}
                  labelId="role-id"
                  {
                    ...register(
                      'role',
                      {
                        required:true
                      }
                    )
                  }
                  fullWidth
                >
                  {/* <MenuItem value='admin'>Admin</MenuItem> 
                  <MenuItem value='user'>User</MenuItem>
                </Select> 
                {/* <Typography>{errors.role && 'role is required'}</Typography> 
              </div>

              <FormControl variant="standard" fullWidth sx={{ width: "90%" }}>
                <InputLabel id="previlliage">Select Previlliage</InputLabel>
                <Select
                  {...register("previlliages", { required: true })}
                  labelId="previlliage"
                >
                  {previlliages.map((prev, index) => (
                    <MenuItem value={prev._id}>{prev.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div
                className="form-fields"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <label>Valid till*</label>
                <TextField
                  {...register("validity", {
                    required: true,
                  })}
                  type={"date"}
                  variant="standard"
                  fullWidth
                />
                <Typography>
                  {errors.validity && "Validity is required"}
                </Typography>
              </div>
              <div
                className="form-fields"
                style={{ display: "flex", flexDirection: "column" }}
              >
                {/* <label>Valid till*</label> 
                <TextField
                  {...register("screenLimit", {
                    required: true,
                  })}
                  type={"number"}
                  variant="standard"
                  lebel={"Screen Limit"}
                  fullWidth
                />
                <Typography>
                  {errors.screenLimit && "Screen Limit is required"}
                </Typography>
              </div>
              {/* </div> 
              <div className="add-user-form-submit-btn">
                <button type={"submit"}>{editMode ? "Update" : "Add"}</button>
              </div>
            </div>
            {/* </form> 
          </DialogContent>
        </Dialog> */}

        <Dialog
          // maxWidth={'xl'}
          open={openEditForm}
          onClose={onCloseEditForm}
          sx={{
            "& .MuiPaper-root-MuiDialog-paper": {
              borderRadius: "16px",
            },
          }}
          className="dialogBorder"
        >
          <DialogTitle
            style={{
              textAlign: "center",
              borderBottom: "2px solid var(--primary-color)",
              fontSize: "18px",
              fontWeight: 700,
            }}
          >
            Edit User
          </DialogTitle>
          <DialogContent
            sx={{
              pb: 5,
            }}
          >
            <form onSubmit={handleSubmit((data) => onEditUser(data))}>
              <div className="user-add-form-container">
                <div className="form-fields">
                  <TextField
                    {...register("username", {
                      required: true,
                    })}
                    type={"text"}
                    variant={"standard"}
                    label={"Name*"}
                    fullWidth
                  />
                  <Typography>
                    {errors.username && "username is required"}
                  </Typography>
                </div>
                <div className="form-fields">
                  <TextField
                    {...register("email", {
                      required: true,
                    })}
                    type={"email"}
                    variant={"standard"}
                    label={"forexample@gmail.com"}
                    fullWidth
                  />
                  <Typography>{errors.email && "email is required"}</Typography>
                </div>
                <div className="form-fields">
                  <FormControl sx={{ width: "100%" }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-password">
                      Password*
                    </InputLabel>
                    <Input
                      {...register("password", {
                        required: true,
                      })}
                      id="standard-adornment-password"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? (
                              <VisibilityOff
                                sx={{
                                  color: "#551FFF",
                                }}
                              />
                            ) : (
                              <Visibility
                                sx={{
                                  color: "#551FFF",
                                }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <Typography>
                    {errors.password && "password is required"}
                  </Typography>
                </div>

                <div className="form-fields">
                  <FormControl variant={"standard"} fullWidth>
                    <InputLabel id="role-id">Role</InputLabel>
                    <Input
                      labelId={"role-id"}
                      type={"text"}
                      readOnly
                      disabled
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                    />
                  </FormControl>
                  {/* <Select
                  variant={'standard'}
                  labelId="role-id"
                  {
                    ...register(
                      'role',
                      {
                        required:true
                      }
                    )
                  }
                  fullWidth
                >
                  {/* <MenuItem value='admin'>Admin</MenuItem> 
                  <MenuItem value='user'>User</MenuItem>
                </Select> */}
                  {/* <Typography>{errors.role && 'role is required'}</Typography> */}
                </div>
                {/* {
                editMode ? (
                  <FormControl
                    variant="standard"
                    fullWidth
                    sx={{ width: "90%" }}
                  >
                    <InputLabel id="previlliage">Select Previlliage</InputLabel>
                    <Select
                      value={previlliageId}
                      onChange={(e) => setPrevilliageId(e.target.value)}
                      labelId="previlliage"
                    >
                      {previlliages.map((prev, index) => (
                        <MenuItem value={prev._id}>{prev.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                )} */}
                <FormControl variant="standard" fullWidth sx={{ width: "90%" }}>
                  <InputLabel id="previlliage">Select Previlliage</InputLabel>
                  <Select
                    {...register("previlliages", { required: true })}
                    labelId="previlliage"
                    value={selectedVal}
                  >
                    {previlliages.map((prev, index) => (
                      <MenuItem value={prev._id}>{prev.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div
                  className="form-fields"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label>Valid till*</label>
                  <TextField
                    {...register("validity", {
                      required: true,
                    })}
                    type={"date"}
                    variant="standard"
                    fullWidth
                  />
                  <Typography>
                    {errors.validity && "Validity is required"}
                  </Typography>
                </div>
                <div
                  className="form-fields"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  {/* <label>Valid till*</label> */}
                  <TextField
                    {...register("screenLimit", {
                      required: true,
                    })}
                    type={"number"}
                    variant="standard"
                    lebel={"Screen Limit"}
                    fullWidth
                  />
                  <Typography>
                    {errors.screenLimit && "Screen Limit is required"}
                  </Typography>
                </div>
                {/* </div> */}
                <div className="add-user-form-submit-btn">
                  <button type={"submit"}>{editMode ? "Update" : "Add"}</button>
                </div>
              </div>
            </form>
          </DialogContent>
        </Dialog>
      </Layout>
    </>
  );
}

export default User;
