import React, { useCallback, useEffect, useState } from "react";
import { imgUrl } from "../../../../api/api";
import { Menu, MenuItem } from "@mui/material";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { DeleteOutlineOutlined, LanguageOutlined } from "@mui/icons-material";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  playlistContentSort,
  playlistFlagOff,
} from "../../../../Redux/Actions/playlistAction";
// import { ReactComponent as DeleteIcon } from "../../../../Assets/images/delete.svg";
function PlaylistContentCardItem(props) {
  const { isSorted, user } = useSelector(
    (state) => ({ isSorted: state.Playlist.isSorted, user: state.User.user }),
    shallowEqual
  );
  const [pageNumber, setPageNumber] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const ref = React.useRef(null);
  const open = Boolean(anchorEl);
  const [startIndex, setStartIndex] = useState("");
  const [lastIndex, setLastIndex] = useState("");
  const dispatch = useDispatch();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (type, obj) => {
    setAnchorEl(null);
  };

  const handleSorting = (e) => {
    const _first = e.dataTransfer.getData("firstIndex");
    dispatch(
      playlistContentSort({
        playlistId: props.playID,
        from: _first,
        to: lastIndex,
      })
    );

    //toast.success("Playlist sorted successfully.");
  };
  const FirstIndex = (e, id) => {
    e.stopPropagation();
    e.dataTransfer.setData("firstIndex", id);
    //setStartIndex(id)
  };
  const LastIndex = (e, id) => {
    e.preventDefault();
    setLastIndex(id);
    // setLastIndex(id)
  };
  return (
    <>
      {/* <div className="content-card-item">
        
     </div> */}
      <>
        <div
          className="acontent-card-item"
          draggable
          onDragStart={(e) => FirstIndex(e, props.content._id)}
          onDragEnter={(e) => {
            e.stopPropagation();
            //  LastIndex(props.content._id)
          }}
          onDragOver={(e) => LastIndex(e, props.content._id)}
          // onDragEnd={(e)=>}
          onDrop={(e) => handleSorting(e)}
        >
          <div
            className="acontent-card-item-body"
            style={{ display: "flex", alignItems: "center", marginTop: "12px" }}
          >
            {props.content.type === "image" && (
              <div
                style={{
                  display: "flex",
                  width: "45vw",
                }}
              >
                {" "}
                <img
                  style={{
                    height: "50px",
                    width: "50px",
                    borderRadius: "8px",
                  }}
                  src={`${props.content.url}`}
                  alt=""
                />
                <div
                  style={{
                    marginRight: "10px",
                    marginTop: "15px",
                    width: "8vw",
                    marginLeft: "30px",
                    marginBottom: "10px",
                    overflowX: "hidden",
                  }}
                >
                  <span>{props.content?.name?.substring(0, 12)}</span>
                </div>
                <div
                  className="wrapicons"
                  style={{ display: "flex", width: "84px" }}
                >
                  <div
                    onClick={() => {
                      handleClose();
                      props.deleteContent(
                        props.playID,
                        props.content._id
                        // play?.activescreen?._id
                      );
                    }}
                    style={{
                      color: "rgba(0,0,0,0.5",
                      marginTop: "13px",
                      marginLeft: "12px",
                      display: `${user.playlist.canDelete ? "flex" : "none"}`,
                    }}
                  >
                    <CloseIcon />
                  </div>
                  <div
                    // onClick={()=>alert('hello')}
                    onClick={() =>
                      props.updateTimeHandler(
                        props.playID,
                        props.content._id,
                        props.content.duration
                        // play?.activescreen?.mac
                      )
                    }
                    className={`${
                      props.content.type !== "video" && "timer-background"
                    } timer flex  alignItemCenter`}
                    style={{
                      color: "rgba(0,0,0,0.7",
                      marginTop: "8px",
                      display: `${user.playlist.canEdit ? "flex" : "none"}`,
                    }}
                  >
                    {props.content.type !== "video" && (
                      <>
                        {/* <AccessTimeIcon /> */}
                        <div>
                          {Math.floor(
                            (props.content?.duration / 1000 / 60) << 0
                          )}
                          :{Math.floor((props.content?.duration / 1000) % 60)}s
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    onClick={() => {
                      handleClose();
                      props.addSchedule(props.playID, props.content._id);
                    }}
                    style={{
                      color: "rgba(0,0,0,0.4",
                      marginTop: "12px",
                      width: "10px",
                      marginRight: "-20px",
                      display: `${user.playlist.canEdit ? "flex" : "none"}`,
                    }}
                  >
                    <EditIcon />
                  </div>
                </div>
              </div>
            )}

            {props.content.type === "video" && (
              <div
                style={{
                  display: "flex",
                  width: "45vw",
                }}
              >
                {" "}
                <video src={`${props.content.url}`} style={{height:'50px',width:'50px'}} />
                <div
                  style={{
                    marginRight: "10px",
                    marginTop: "15px",
                    width: "8vw",
                    marginLeft: "30px",
                    marginBottom: "10px",
                    overflowX: "hidden",
                  }}
                >
                  <span>{props.content?.name?.substring(0, 12)}</span>
                </div>
                <div
                  className="wrapicons"
                  style={{ display: "flex", width: "84px" }}
                >
                  <div
                    onClick={() => {
                      handleClose();
                      props.deleteContent(
                        props.playID,
                        props.content._id
                        // play?.activescreen?._id
                      );
                    }}
                    style={{
                      color: "rgba(0,0,0,0.5",
                      marginTop: "13px",
                      marginLeft: "12px",
                      display: `${user.playlist.canDelete ? "flex" : "none"}`,
                    }}
                  >
                    <CloseIcon />
                  </div>
                  <div
                    // onClick={()=>alert('hello')}
                    onClick={() =>
                      props.updateTimeHandler(
                        props.playID,
                        props.content._id,
                        props.content.duration
                        // play?.activescreen?.mac
                      )
                    }
                    className={`${
                      props.content.type !== "video" && "timer-background"
                    } timer flex  alignItemCenter`}
                    style={{
                      color: "rgba(0,0,0,0.7",
                      marginTop: "8px",
                      display: `${user.playlist.canEdit ? "flex" : "none"}`,
                    }}
                  >
                    {props.content.type !== "video" && (
                      <>
                        {/* <AccessTimeIcon /> */}
                        <div>
                          {Math.floor(
                            (props.content?.duration / 1000 / 60) << 0
                          )}
                          :{Math.floor((props.content?.duration / 1000) % 60)}s
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    onClick={() => {
                      handleClose();
                      props.addSchedule(props.playID, props.content._id);
                    }}
                    style={{
                      color: "rgba(0,0,0,0.4",
                      marginTop: "12px",
                      width: "10px",
                      marginRight: "-20px",
                      display: `${user.playlist.canEdit ? "flex" : "none"}`,
                    }}
                  >
                    <EditIcon />
                  </div>
                </div>
              </div>
            )}
            {props.content.type === "url" && (
              <div
                style={{
                  display: "flex",
                  width: "45vw",
                }}
              >
                <LanguageOutlined
                  style={{
                    height: "50px",
                    width: "50px",
                    borderRadius: "8px",
                  }}
                />
                <div
                  className="wrapicons"
                  style={{ display: "flex", width: "84px" }}
                >
                  <div
                    onClick={() => {
                      handleClose();
                      props.deleteContent(
                        props.playID,
                        props.content._id
                        // play?.activescreen?._id
                      );
                    }}
                    style={{
                      color: "rgba(0,0,0,0.5",
                      marginTop: "13px",
                      marginLeft: "12px",
                      display: `${user.playlist.canDelete ? "flex" : "none"}`,
                    }}
                  >
                    <CloseIcon />
                  </div>
                  <div
                    // onClick={()=>alert('hello')}
                    onClick={() =>
                      props.updateTimeHandler(
                        props.playID,
                        props.content._id,
                        props.content.duration
                        // play?.activescreen?.mac
                      )
                    }
                    className={`${
                      props.content.type !== "video" && "timer-background"
                    } timer flex  alignItemCenter`}
                    style={{
                      color: "rgba(0,0,0,0.7",
                      marginTop: "8px",
                      display: `${user.playlist.canEdit ? "flex" : "none"}`,
                    }}
                  >
                    {props.content.type !== "video" && (
                      <>
                        {/* <AccessTimeIcon /> */}
                        <div>
                          {Math.floor(
                            (props.content?.duration / 1000 / 60) << 0
                          )}
                          :{Math.floor((props.content?.duration / 1000) % 60)}s
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    onClick={() => {
                      handleClose();
                      props.addSchedule(props.playID, props.content._id);
                    }}
                    style={{
                      color: "rgba(0,0,0,0.4",
                      marginTop: "12px",
                      width: "10px",
                      marginRight: "-20px",
                      display: `${user.playlist.canEdit ? "flex" : "none"}`,
                    }}
                  >
                    <EditIcon />
                  </div>
                </div>
              </div>
            )}
            {
              props.content.type === "application" && (
                <div
                  style={{
                    display: "flex",
                    width: "45vw",
                  }}
                >
                  <PictureAsPdfOutlinedIcon
                    style={{
                      height: "50px",
                      width: "50px",
                      borderRadius: "8px",
                    }}
                  />
                  <div
                    style={{
                      marginRight: "10px",
                      marginTop: "7px",
                      width: "8vw",
                      marginLeft: "30px",
                      marginBottom: "10px",
                      overflowX: "hidden",
                    }}
                  >
                    <span>{props.content?.name?.substring(0, 16)}</span>
                  </div>
                  <div
                    className="wrapicons"
                    style={{ display: "flex", width: "84px" }}
                  >
                    <div
                      onClick={() => {
                        handleClose();
                        props.deleteContent(
                          props.playID,
                          props.content._id
                          // play?.activescreen?._id
                        );
                      }}
                      style={{
                        color: "rgba(0,0,0,0.5",
                        marginTop: "13px",
                        marginLeft: "12px",
                        display: `${user.playlist.canDelete ? "flex" : "none"}`,
                      }}
                    >
                      <CloseIcon />
                    </div>
                    <div
                      // onClick={()=>alert('hello')}
                      onClick={() =>
                        props.updateTimeHandler(
                          props.playID,
                          props.content._id,
                          props.content.duration
                          // play?.activescreen?.mac
                        )
                      }
                      className={`${
                        props.content.type !== "video" && "timer-background"
                      } timer flex  alignItemCenter`}
                      style={{
                        color: "rgba(0,0,0,0.7",
                        marginTop: "8px",
                        display: `${user.playlist.canEdit ? "flex" : "none"}`,
                      }}
                    >
                      {props.content.type !== "video" && (
                        <>
                          {/* <AccessTimeIcon /> */}
                          <div>
                            {Math.floor(
                              (props.content?.duration / 1000 / 60) << 0
                            )}
                            :{Math.floor((props.content?.duration / 1000) % 60)}
                            s
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      onClick={() => {
                        handleClose();
                        props.addSchedule(props.playID, props.content._id);
                      }}
                      style={{
                        color: "rgba(0,0,0,0.4",
                        marginTop: "12px",
                        width: "10px",
                        marginRight: "-20px",
                        display: `${user.playlist.canEdit ? "flex" : "none"}`,
                      }}
                    >
                      <EditIcon />
                    </div>
                  </div>
                </div>
              )
              // <Document
              // file={`${imgUrl}/${props.content?.url}`}
              //   onLoadError={(error) => console.log("failed=", error)}
              //   onLoadSuccess={({ numPages }) => setPageNumber(numPages)}
              // >
              //   <Page  pageNumber={pageNumber}
              //     height={'130'}
              //     width={'130'}
              //     renderTextLayer={false}
              //     renderAnnotationLayer={false}  />
              // </Document>
            }
            {props.content.type === "widget" && (
              <div
                style={{
                  display: "flex",
                  width: "45vw",
                }}
              >
                <img
                  src={`${props.content?.widgetIcon}`}
                  style={{
                    height: "50px",
                    width: "50px",
                    borderRadius: "8px",
                  }}
                />
                <div
                  style={{
                    marginRight: "24px",
                    marginTop: "15px",
                    width: "6vw",
                    marginLeft: "30px",
                    marginBottom: "10px",
                    overflowX: "hidden",
                  }}
                >
                  <span>{props.content?.name?.substring(0, 16)}</span>
                </div>
                <div
                  className="wrapicons"
                  style={{ display: "flex", width: "84px" }}
                >
                  <div
                    onClick={() => {
                      handleClose();
                      props.deleteContent(
                        props.playID,
                        props.content._id
                        // play?.activescreen?._id
                      );
                    }}
                    style={{
                      color: "rgba(0,0,0,0.5",
                      marginTop: "13px",
                      marginLeft: "12px",
                      display: `${user.playlist.canDelete ? "flex" : "none"}`,
                    }}
                  >
                    <CloseIcon />
                  </div>
                  <div
                    // onClick={()=>alert('hello')}
                    onClick={() =>
                      props.updateTimeHandler(
                        props.playID,
                        props.content._id,
                        props.content.duration
                        // play?.activescreen?.mac
                      )
                    }
                    className={`${
                      props.content.type !== "video" && "timer-background"
                    } timer flex  alignItemCenter`}
                    style={{
                      color: "rgba(0,0,0,0.7",
                      marginTop: "8px",
                      display: `${user.playlist.canEdit ? "flex" : "none"}`,
                    }}
                  >
                    {props.content.type !== "video" && (
                      <>
                        {/* <AccessTimeIcon /> */}
                        <div>
                          {Math.floor(
                            (props.content?.duration / 1000 / 60) << 0
                          )}
                          :{Math.floor((props.content?.duration / 1000) % 60)}s
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    onClick={() => {
                      handleClose();
                      props.addSchedule(props.playID, props.content._id);
                    }}
                    style={{
                      color: "rgba(0,0,0,0.4",
                      marginTop: "12px",
                      width: "10px",
                      marginRight: "-20px",
                      display: `${user.playlist.canEdit ? "flex" : "none"}`,
                    }}
                  >
                    <EditIcon />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    </>
  );
}

export default PlaylistContentCardItem;
