import React, { useState } from "react";
import PhotoCameraBackIcon from "@mui/icons-material/PhotoCameraBack";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { imgUrl } from "../../../api/api";
import { ReactComponent as JpgIcon } from "../../../Assets/images/jpgIcon.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Menu, MenuItem } from "@mui/material";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import {
  Folder,
  PlayArrowOutlined,
  PlayCircleFilledOutlined,
  VideoCameraBack,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
function Vedio({ data, addSchedule, deleteSingleContent, onOpenFolderModel }) {
  const {user}=useSelector(state=>state.User);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (type, obj) => {
    setAnchorEl(null);
  };
  return (
    <>
      <div className="content-item" key={data.key}>
        <div className="content-image">
          <div className="content-del-icon">
            <MoreVertIcon
              onClick={handleClick}
              aria-controls={open ? "content-menu" : undefined}
              aria-haspopup={"true"}
              aria-expanded={open ? "true" : undefined}
              id="content-menu-btn"
            />
            <Menu
              sx={{ zIndex: "11111111111" }}
              id={"content-menu"}
              anchorEl={anchorEl}
              open={open}
              MenuListProps={{
                "aria-labelledby": "content-menu-btn",
              }}
              onClose={handleClose}
              keepMounted
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  deleteSingleContent(data.id);
                }}
                sx={{
                  display: `${user?.content?.canDelete ? "flex" : "none"}`,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <DeleteOutlineIcon />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  onOpenFolderModel(data.id);
                }}
                sx={{
                  display: `${user?.folder?.canEdit ? "flex" : "none"}`,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Folder sx={{ cursor: "pointer" }} />
              </MenuItem>
            </Menu>
          </div>
          <video src={`${data.url}`} controls />
        </div>
        <div className="content-bottom">
          <p>{data?.name?.split("--")[0]?.substring(0, 20)}</p>
          <p
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span>
              <PlayCircleFilledOutlined
                sx={{
                  fontSize: "17px",
                }}
              />
              {/* <JpgIcon sx={{
                fontSize: '17px'
              }} /> */}
            </span>
            <span>{data.type}</span>
          </p>
        </div>
      </div>
    </>
  );
}

export default Vedio;
