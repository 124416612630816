import React from 'react'
import { useDispatch, useSelector } from "react-redux";

import "./dashboard.css";
import LinearProgress, {
    linearProgressClasses,
  } from "@mui/material/LinearProgress";
  import { styled, alpha } from "@mui/material/styles";

import { Grid, List, ListItem, Typography,Box } from "@mui/material";const DashboardBottomGraphs = () => {
    const CustomLinearProgress = styled(LinearProgress)(({ theme, color }) => ({
        height: 10,
        borderRadius: 5,
        [`& .${linearProgressClasses.bar}`]: {
          borderRadius: 5,
          backgroundColor: color,
        },
      }));
      // const dispatch = useDispatch();
      const { content, isContentLoading, contents } = useSelector(
        (state) => state.Content
      );
     console.log(content.url)
      const contentTypes = [
        { label: "URL", type: "url", color: "hsla(209, 99%, 65%, 1)" },
        { label: "Image", type: "image", color: "hsla(143, 53%, 50%, 1)" },
        { label: "Video", type: "video", color: "hsla(14, 81%, 46%, 1)" },
        { label: "Widgets", type: "widget", color: "hsla(337, 79%, 61%, 1)" },
      ];
      console.log(contents)
  return (
   <>
     <Grid lg={11.9} md={3.5}>
           
                  <Box >
      <Typography variant="h6" gutterBottom>
        Content Type
      </Typography>
      <Typography variant="subtitle1" color="textSecondary">
        Last Campaign Performance
      </Typography>
      {!isContentLoading &&
          contentTypes.map((typeData, index) => {
            // Calculate progress dynamically
            const count = contents.filter((c) => c.type === typeData.type).length;
            const total = contents.length || 1; // Prevent division by 0
            const progress = (count / total) * 100;

            return (
              <Box key={index} sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
                <div
                  className="valueswrap"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                    {typeData.label}
                  </Typography>
                  <Typography variant="body2">{count}</Typography>
                </div>
                <Box sx={{ width: "100%" }}>
                  <CustomLinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: typeData.color,
                      },
                    }}
                  />
                </Box>
              </Box>
            );
          })}
      {/* {contentData.map((Content, index) => (
        <Box key={index} sx={{ display: 'flex',flexDirection:"column", mt: 2 }}>
        <div className="valueswrap" style={{display:"flex",justifyContent:"space-between"}}>
        <Typography variant="body2" sx={{  fontWeight: 'bold' }}>
            {Content.label}
          </Typography>
          <Typography variant="body2">
            {Content.value}
          </Typography>
        </div>
          <Box sx={{ width: '100%'}}>
            <CustomLinearProgress
              variant="determinate"
              value={[...content.filter((c) => c.type === "url")].length} 
              sx={{
                '& .MuiLinearProgress-bar': {
                  backgroundColor: content.color,
                },
              }}
            />
          </Box>
         
        </Box>
      ))} */}
    </Box>
            </Grid>
               {/* <Box
                sx={{
                  background: "#FFFFFF",
                  borderRadius: "20px",
                  padding: "20px 10px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <DonutChart series={[44, 55, 13, 33, 55, 33, 22]} labels={['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']} />
                <List
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    padding: 0,
                    fontSize: "12px",
                  }}
                >
                  <CustomListItem>
                    <span
                      style={{
                        background: "#0068b9",
                        width: "7px",
                        height: "7px",
                        // borderRadius: '50%',
                        marginRight: "5px",
                      }}
                    ></span>
                    <span>Mon</span>
                  </CustomListItem>
                  <CustomListItem>
                    <span
                      style={{
                        background: "#00e396",
                        width: "7px",
                        height: "7px",
                        // borderRadius: '50%',
                        marginRight: "5px",
                      }}
                    ></span>
                    <span>Tue</span>
                  </CustomListItem>
                  <CustomListItem>
                    <span
                      style={{
                        background: "#feb019",
                        width: "7px",
                        height: "7px",
                        // borderRadius: '50%',
                        marginRight: "5px",
                      }}
                    ></span>
                    Wed
                  </CustomListItem>
                  <CustomListItem>
                    <span
                      style={{
                        background: "#bb2e45",
                        width: "7px",
                        height: "7px",
                        // borderRadius: '50%',
                        marginRight: "5px",
                      }}
                    ></span>

                    <span>Thu</span>
                  </CustomListItem>
                  <CustomListItem>
                    <span
                      style={{
                        background: "#775dd0",
                        width: "7px",
                        height: "7px",
                        // borderRadius: '50%',
                        marginRight: "5px",
                      }}
                    ></span>

                    <span>Fri</span>
                  </CustomListItem>
                  <CustomListItem>
                    <span
                      style={{
                        background: "#008ffb",
                        width: "7px",
                        height: "7px",
                        // borderRadius: '50%',
                        marginRight: "5px",
                      }}
                    ></span>
                    Sat
                    <span></span>
                  </CustomListItem>
                  <CustomListItem>
                    <span
                      style={{
                        background: "#00e396",
                        width: "7px",
                        height: "7px",
                        // borderRadius: '50%',
                        marginRight: "5px",
                      }}
                    ></span>

                    <span>Sun</span>
                  </CustomListItem>
                </List>
              </Box> */}
              {/* <Box
                sx={{
                  background: "#FFFFFF",
                  borderRadius: "20px",
                  padding: "25px 20px 65px 20px",
                  marginTop: "20px",
                }}
              >
                <div className="progress-bar-title">
                  <Typography
                    sx={{
                      color: "#15192C",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Content Type
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "#92959E",
                      mb: 2,
                    }}
                  >
                    Last Campaign Performance
                  </Typography>
                </div>
                <div className="progress">
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                    >
                      Url
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                      }}
                    >
                      {isContentLoading ? (
                        <Box className="minLoader"></Box>
                      ) : (
                        [...content.filter((c) => c.type === "url")].length
                      )}
                    </Typography>
                  </Box>
                  <LinearProgress
                    variant={"determinate"}
                    value={[...content.filter((c) => c.type === "url")].length}
                    color={"url"}
                  />
                  <br />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                    >
                      Image
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                      }}
                    >
                      {isContentLoading ? (
                        <Box className="minLoader"></Box>
                      ) : (
                        [...content.filter((c) => c.type === "image")].length
                      )}
                    </Typography>
                  </Box>
                  <LinearProgress
                    variant={"determinate"}
                    value={
                      [...content.filter((c) => c.type === "image")].length
                    }
                    color={"image"}
                  />
                  <br />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                    >
                      Video
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                      }}
                    >
                      {isContentLoading ? (
                        <Box className="minLoader"></Box>
                      ) : (
                        [...content.filter((c) => c.type === "video")].length
                      )}
                    </Typography>
                  </Box>
                  <LinearProgress
                    variant={"determinate"}
                    value={
                      [...content.filter((c) => c.type === "video")].length
                    }
                    color={"video"}
                  />
                    <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                      }}
                    >
                      Video
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                      }}
                    >
                      {isContentLoading ? (
                        <Box className="minLoader"></Box>
                      ) : (
                        [...content.filter((c) => c.type === "video")].length
                      )}
                    </Typography>
                  </Box>
                   <LinearProgress
                    variant={"determinate"}
                    value={
                      [...content.filter((c) => c.type === "image")].length
                    }
                    color={"image"}
                  />
                  
                </div>
              </Box> */}
   </>
  )
}

export default DashboardBottomGraphs