import { Folder } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Search } from "lucide-react";
import React, { useEffect, useState, useCallback } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { api } from "../../api/api";
import {
  addFolderToPlaylist,
  editFolderToPlaylist,
} from "../../Redux/Actions/playlistAction";
import useFilter from "../../hooks/useFilter";

function FolderAssignToPlaylist({
  open,
  onClose,
  isEditMode,
  folderId,
  _playlistId,
}) {
  const dispatch = useDispatch();
  const { _folders } = useSelector(
    (state) => ({
      _folders: state.Folder.folders.filter((f) => f.module == "content"),
    }),
    shallowEqual
  );
  const [searchVal, setSearchVal] = useState("");
  const filtered = useFilter(searchVal, _folders);
  const [selectedFolders, setSelectedFolders] = useState([]);

  useEffect(() => {
    if (isEditMode) {
      setSelectedFolders([folderId]);
    }
  }, [isEditMode]);

  // Toggle folder selection
  const toggleFolderSelection = (id, e) => {
    console.log(id, e.target.checked);
    if (e.target.checked) {
      if (selectedFolders.length == 1) {
        alert("limit acceeded.");
        return;
      }
      setSelectedFolders((prevSelected) =>
        prevSelected.includes(id)
          ? prevSelected.filter((folderId) => folderId !== id)
          : [...prevSelected, id]
      );
    } else {
      setSelectedFolders((prevSelected) =>
        prevSelected.includes(id)
          ? prevSelected.filter((folderId) => folderId !== id)
          : [...prevSelected, id]
      );
    }
  };

  const _onClose = () => {
    setSelectedFolders([]);
    onClose();
  };
  const onSubmit = () => {
    dispatch(addFolderToPlaylist({ id: _playlistId, value: selectedFolders }));
    _onClose();
  };
  const onEdit = () => {
    dispatch(
      editFolderToPlaylist({
        id: _playlistId,
        value: selectedFolders,
        oldId: folderId,
      })
    );
    _onClose();
  };
  const onSearch = useCallback(
    (e) => {
      setSearchVal(e.target.value);
    },
    [searchVal]
  );
  console.log(selectedFolders);
  return (
    <>
      <Dialog open={open} onClose={_onClose}>
        <DialogTitle>
          {isEditMode ? <>Change Folder</> : <>Add Folder</>}
        </DialogTitle>
        <DialogContent sx={{ background: "#fafafa" }}>
          <Box
            sx={{
              height: "52dvh",
              width: "20dvw",
              padding: "2px",
              background: "#fafafa",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                backgruond: "#ffffff",
                padding: "1px",
                width: "100%",
                border: "1px solid gray",
                borderRadius: "40px",
                height: "5vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Search />
              <input
                type="text"
                value={searchVal}
                onChange={onSearch}
                style={{
                  backgruond: "#ffffff",
                  height: "4.5vh",
                  width: "90%",
                  border: "1px solid white",
                  borderRadius: "50px",
                  padding: "2px",
                }}
                placeholder="Search folder"
              />
            </Box>
            <Box
              sx={{
                minHeight: "40.5vh",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  maxHeight: "40vh",
                  overflowY: "auto",
                  overflowX: "hidden",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "2px",
                }}
              >
                {filtered.map((fol, index) => {
                  return (
                    <>
                      <label
                        htmlFor={`folder_checked-${index}`}
                        key={index}
                        // onClick={() => toggleFolderSelection(fol._id)}
                        style={{
                          cursor: "pointer",
                          minHeight: "8vh",
                          width: "100%",
                          border: "1px solid lightgray",
                          backgroundColor: selectedFolders.includes(fol._id)
                            ? "lightgray"
                            : "#fafafa",
                          display: "flex",
                          gap: "5px",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          borderRadius: "5px",
                        }}
                      >
                        <Folder
                          style={{ color: "#ffca28", fontSize: "50px" }}
                        />
                        <p>{fol.name}</p>
                        <input
                          id={`folder_checked-${index}`}
                          type="checkbox"
                          onChange={(e) => toggleFolderSelection(fol._id, e)}
                          checked={selectedFolders.includes(fol._id)}
                          readOnly
                          style={{ marginRight: "8px", display: "none" }}
                        />
                      </label>
                    </>
                  );
                })}
              </Box>
            </Box>
            <Box
              sx={{
                minHeight: "5vh",
                width: "100%",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <button
                onClick={_onClose}
                style={{
                  height: "100%",
                  minWidth: "100px",
                  background: "transparent",
                  color: "black",
                  textTransform: "capitalize",
                  borderRadius: "10px",
                  border: "1px solid lightgray",
                  cursor: "pointer",
                }}
              >
                Cancel
              </button>{" "}
              <button
                onClick={isEditMode ? onEdit : onSubmit}
                style={{
                  height: "100%",
                  width: "100px",
                  background: "var(--primary-color)",
                  border: "1px solid transparent",
                  color: "white",
                  textTransform: "capitalize",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
              >
                {isEditMode ? <>Update</> : <>Save</>}
              </button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default FolderAssignToPlaylist;
